<template>
    <!--新增对话框-->
    <el-dialog title="订单详情" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="handleClose">
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        订单编码：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.billCode }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        订单状态：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.billStateStr }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        装货时间：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.loadingTime }}
                    </el-col>
                </el-row>
            </el-col>

        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        起始点：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.startingPoint }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        终止点：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.endPoint }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        里程(km)：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.estimatedMileage }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        最终运费：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.finalFreight }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        货物：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.goods }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        成交方式：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.transactionMethod == 1">一口价</span>
                        <span v-if="detail.transactionMethod == 2">货主定价</span>
                        <span v-if="detail.transactionMethod == 3">议价</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        货车数量：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.truckCount }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        是否回单：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.receiptInformation == 1">不需要回单</span>
                        <span v-if="detail.receiptInformation == 2">需要回单</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        是否投保：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.isInsure == 1">是</span>
                        <span v-if="detail.isInsure == 0">否</span>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        是否开票：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.isDrawBill == 0">未开票</span>
                        <span v-if="detail.isDrawBill == 1">已开票</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        下单用户：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.userName }}{{ detail.userPhone }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        下单时间：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.createdTime"> {{ format(new Date(detail.createdTime), 'yyyy-MM-dd hh:mm:ss')
                        }}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        货车司机：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.truckUserName }}{{ detail.truckUserPhone }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        接单时间：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.orderGrabbingTime"> {{ format(new Date(detail.orderGrabbingTime), 'yyyy-MM-dd
                                                    hh: mm: ss') }}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        揽货时间：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.pickingTime"> {{ format(new Date(detail.pickingTime), 'yyyy-MM-dd hh:mm:ss')
                        }}</span>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        送达时间：
                    </el-col>
                    <el-col :span="18">
                        <span v-if="detail.deliveryTime"> {{ format(new Date(detail.deliveryTime), 'yyyy-MM-dd hh:mm:ss')
                        }}</span>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        车辆类型：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.vehicleType }}
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        特殊备注：
                    </el-col>
                    <el-col :span="18">
                        {{ detail.specialRemarks }}
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="20">

            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        车头照片：
                    </el-col>
                    <el-col :span="18">
                        <el-image v-if="detail.headPhoto" style="width: 100px; height: 100px" :src="detail.headPhoto"
                            fit="scale-down" @click="imageUrl = detail.headPhoto, imgVisible = true"></el-image>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :span="12">
                <el-row :gutter="20">
                    <el-col :span="6">
                        车尾照片：
                    </el-col>
                    <el-col :span="18">
                        <el-image v-if="detail.rearPhoto" style="width: 100px; height: 100px" :src="detail.rearPhoto"
                            fit="scale-down" @click="imageUrl = detail.rearPhoto, imgVisible = true"></el-image>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>

        <el-dialog title="预览" :visible.sync="imgVisible" :before-close="imgClose" append-to-body>
            <div style="text-align: center;">
                <el-image v-if="detail.rearPhoto" style="width: 400px; height: 400px;text-align: center;" :src="imageUrl"
                    fit="scale-down"></el-image>
            </div>

        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            imgVisible: false,
            imageUrl: null,
            detail: {},
            dialogVisible: false,
        }
    },
    methods: {
        format(date, fmt) {
            var o = {
                "M+": date.getMonth() + 1,                 //月份 
                "d+": date.getDate(),                    //日 
                "h+": date.getHours(),                   //小时 
                "m+": date.getMinutes(),                 //分 
                "s+": date.getSeconds(),                 //秒 
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
                "S": date.getMilliseconds()             //毫秒 
            };
            if (/(y+)/.test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            }
            for (var k in o) {
                if (new RegExp("(" + k + ")").test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
                }
            }
            return fmt;
        },
        imgClose() {
            this.imgVisible = false
        },
        handleClose() {
            this.dialogVisible = false
        },
        init(billId) {
            this.dialogVisible = true;
            this.$axios.post('/admin/bill/getBillPageInfo', { billId: billId }).then(res => {
                this.detail = JSON.parse(JSON.stringify(res.data))
            })
        },
    }
}
</script>

<style scoped>
.el-col {
    margin-bottom: 10px;
}</style>
